<template>
  <section id="producto-nuevo" v-if="$store.state.appConfig.rol == 3">
    <b-col cols="14">
      <b-card>
        <!-- <div id="loading-bg" v-if="loading">
          <div class="loading">
            <div class="effect-1 effects"></div>
            <div class="effect-2 effects"></div>
            <div class="effect-3 effects"></div>
          </div>
        </div> -->
        <div>
          <!-- Header: Personal Info -->
          <div class="d-flex">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="my-1">Crear nuevo producto</h4>
              </div>
            </div>
          </div>
        </div>

        <!-- Form: Personal Info Form -->
        <b-form class="mt-1">
          <b-row>
            <!-- Field: Nombre -->
            <b-col cols="12" md="6" lg="6">
              <b-form-group label="1. Nombre *" label-for="nombre">
                <b-form-input
                  id="nombre"
                  v-model="nombre"
                  :state="aceptadoNombre"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Pasarelas -->
            <b-col cols="12" md="6" lg="6">
              <b-form-group
                label="2. Pasarelas *"
                label-for="pasarelas"
                :state="aceptadoPasarela"
              >
                <v-select
                  id="selectPasarelas"
                  v-model="pasarelasProducto"
                  :dir="dir"
                  multiple
                  label="title"
                  :options="optionPasarelas"
                  :state="aceptadoPasarela"
                  @input="onChangePasarela"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Medios de pago -->
            <b-col cols="12" md="6" lg="6">
              <b-form-group
                label="3. Medios de Pago *"
                label-for="medios"
                :state="aceptadoMedios"
              >
                <v-select
                  id="selectMediosPago"
                  v-model="medios"
                  :dir="dir"
                  multiple
                  label="title"
                  :options="getMediosPago(pasarelasProducto)"
                  :state="aceptadoMedios"
                />
                <!-- pasarelasProducto == 'Stripe'
                      ? optionMediosStripe
                      : pasarelasProducto == 'Kushki'
                      ? optionMediosKushki
                      : optionMediosSusM -->
              </b-form-group>
            </b-col>

            <!-- Field: Servicios -->
            <b-col cols="12" md="6" lg="6">
              <b-form-group
                label="4. Servicios *"
                label-for="servicios"
                :state="aceptadoServicios"
              >
                <v-select
                  id="selectServicios"
                  v-model="servicios"
                  :dir="dir"
                  multiple
                  label="title"
                  :options="optionServicios"
                  :state="aceptadoServicios"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Codigo -->
            <b-col cols="12" md="6" lg="6">
              <b-form-group
                label="5. Dirección de Códigos de descuento (opc.)"
                label-for="codigo"
              >
                <b-form-input id="codigo" v-model="codigo" type="url" />
              </b-form-group>
            </b-col>

            <!-- Field: País -->
            <!-- <b-col cols="12" md="6" lg="6">
              <b-form-group
                label="6. País *"
                label-for="pais"
                :state="aceptadoPais"
              >
                <v-select
                  id="selectPais"
                  v-model="paises"
                  :dir="dir"
                  multiple
                  label="title"
                  :options="optionPaises"
                  :state="aceptadoPais"
                >
                  <span slot="no-options">No hay opciones disponibles</span>
                </v-select>
              </b-form-group>
            </b-col> -->

            <!-- Field: Moneda -->
            <b-col cols="12" md="6" lg="6">
              <b-form-group
                label="6. Moneda *"
                label-for="Moneda"
                :state="aceptadoMoneda"
              >
                <v-select
                  id="selectMoneda"
                  v-model="monedaBase"
                  :dir="dir"
                  label="name"
                  value="id"
                  @input="ver"
                  :options="getMonedas(pasarelasProducto)"
                  :state="aceptadoMoneda"
                >
                  <span slot="no-options">No hay opciones disponibles</span>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- CREDENCIALES STRIPE -->
          <div v-if="pasarelasProducto.indexOf('Stripe') > -1">
            <label id="label-pasarela-visible">Credenciales de Stripe *</label>
            <b-row>
              <b-col cols="12" md="6" lg="6" class="mx-0">
                <b-form-group
                  label="Clave Pública *"
                  label-for="clavePublicaStripe"
                >
                  <b-form-input
                    id="clavePublicaStripe"
                    v-model="clavePublicaStripe"
                    :state="aceptadoClavePublicaStripe"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6" class="mx-0">
                <b-form-group
                  label="Clave Privada *"
                  label-for="clavePrivadaStripe"
                >
                  <b-form-input
                    id="clavePrivadaStripe"
                    v-model="clavePrivadaStripe"
                    :state="aceptadoClavePrivadaStripe"
                  />
                </b-form-group>
              </b-col>
            </b-row>


          </div>

          <!-- CREDENCIALES KUSHKI -->
          <div v-if="pasarelasProducto.indexOf('Kushki') > -1">
            <label id="label-pasarela-visible">Credenciales de Kushki *</label>
            <b-row>
              <b-col cols="12" md="6" lg="6" class="mx-0">
                <b-form-group
                  label="Clave Pública *"
                  label-for="clavePublicaKushki"
                >
                  <b-form-input
                    id="clavePublicaKushki"
                    v-model="clavePublicaKushki"
                    :state="aceptadoClavePublicaKushki"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6" class="mx-0">
                <b-form-group
                  label="Clave Privada *"
                  label-for="clavePrivadaKushki"
                >
                  <b-form-input
                    id="clavePrivadaKushki"
                    v-model="clavePrivadaKushki"
                    :state="aceptadoClavePrivadaKushki"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <div class="mt-2">
            <label class="label-subtitles"> Términos y Condiciones *</label>
          </div>
          <!-- Field: terminos -->
          <!-- <b-col cols="12" md="6" lg="6"> -->
          <div>
            <b-form-textarea
              id="termsCondiciones"
              v-model="terminosCondiciones"
              rows="6"
              size="lg"
              :state="aceptadoTermsCond"
            ></b-form-textarea>
          </div>
          <!-- </b-col> -->
        </b-form>

        <div class="container p-0 mt-3">
          <div class="row align-items-center">
            <div class="col">
              <label class="label-subtitles"> Tarifas (opc)</label>
              <div
                class="row align-items-center"
                style="margin-left: 0px; margin-bottom: 0; padding: 0"
              >
                <div v-if="!aceptadoTarifas">
                  <feather-icon
                    icon="AlertCircleIcon"
                    size="16"
                    class="m-0 p-0"
                    style="color: red"
                  />
                  <label style="color: red !important; margin-left: 8px"
                    >Campo obligatorio</label
                  >
                </div>
              </div>
            </div>
            <div class="col justify-content-end">
              <div style="display: flex">
                <div style="margin-left: auto">
                  <b-button
                    @click="modalShow = !modalShow"
                    v-b-modal.modal-agregar
                    variant="outline-primary"
                    class="shadow-none"
                    :disabled="monedaBase.length === 0 || tableData.length == 1"
                    >Agregar otra moneda</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Tabla -->
        <b-table
          :items="tableData"
          responsive
          :fields="fields"
          class="mb-0 text-center mt-1"
        >
          <!-- tipo -->
          <template #cell(tipo)="data">
            <div class="d-flex flex-column data-items-size">
              <span>{{ data.item.code }}</span>
            </div>
          </template>

          <!-- fecha -->
          <template #cell(nombre)="data">
            <div class="d-flex flex-column data-items-size">
              <span>{{ data.item.nombre }}</span>
            </div>
          </template>

          <!-- precio -->
          <template #cell(precio)="data">
            <div class="align-items-center">
              <div>
                <div class="font-weight-bolder text-uppercase data-items-size">
                  {{
                    '1 ' +
                      data.item.code +
                      ' = ' +
                      data.item.precio.toFixed(2) +
                      ' ' +
                      monedaBase.code
                  }}
                </div>
              </div>
            </div>
          </template>

          <!-- id -->
          <!-- <template #cell(id)="data">
            <div class="align-items-center data-items-size">
              <span>{{ data.item.id }}</span>
            </div>
          </template> -->

          <!-- fecha
          <template #cell(fecha)="data">
            <div class="d-flex flex-column data-items-size">
              <span>{{ data.item.fecha }}</span>
            </div>
          </template> -->

          <!-- accion -->
          <template #cell(accion)="data">
            <div class="align-items-center">
              <!-- <button
                disabled
                class="action-button pr-2"
                @click="datosEditar(data.item)"
                v-b-modal.modal-editar
              >
                <feather-icon
                  icon="Edit2Icon"
                  size="16"
                  class="text-body align-middle"
                />
              </button> -->
              <!-- @click="modalEliminarTarifaShow = !modalEliminarTarifaShow" -->
              <button
                class="action-button pr-2"
                v-b-modal.modal-eliminar-tarifa
                @click="areyousure(data.item)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="18"
                  class="text-body align-middle"
                />
              </button>
            </div>
          </template>
        </b-table>

        <!-- botones -->
        <div class="row align-items-center mt-5">
          <div>
            <b-alert
              v-height-fade.appear
              :show="dismissCountDown"
              dismissible
              class="mb-0 ml-1"
              variant="warning"
              id="empty-field-popup"
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChanged"
            >
              <div class="alert-body">
                <span class="bold">Advertencia! </span>
                <span>Debes completar los campos obligatorios </span>
              </div>
            </b-alert>
          </div>
          <div class="col justify-content-end">
            <div class="text-md-right text-center">
                <b-button
                  id="cancel-button"
                  variant="outline-primary"
                  class="mr-md-1 mr-1 mb-md-0 mb-1"
                  @click="modalCancelarShow = !modalCancelarShow"
                  v-b-modal.modal-Cancelar
                >
                  Cancelar
                </b-button>
                <!-- tableData.length === 0 ||  -->
                <b-button
                  variant="primary"
                  class="shadow-none mr-md-1 mr-1 mb-md-0 mb-1"
                  @click="validateEmptyFields()"
                  :disabled="$store.state.appConfig.loading"
                >
                  Agregar producto
                </b-button>
            </div>
          </div>
        </div>
      </b-card>
    </b-col>

    <!-- AGREGAR MONEDA -->
    <b-modal
      id="modal-agregar"
      title="Agregar moneda"
      ok-title="Agregar"
      ok-variant="primary shadow-none modal-button-size"
      cancel-title="Cancelar"
      cancel-variant="outline-primary shadow-none modal-button-size"
      hide-footer
      v-model="modalShow"
      centered
      class="w-50"
      ref="modal-agregar"
    >
      <b-form>
        <b-form-group>
          <b-row>
            <b-col>
              <b-form-group
                label="Codigo *"
                label-for="select-codigo"
                label-class="font-weight-bolder label-size"
              >
                <v-select
                  id="selectMonedaName"
                  v-model="currency.monedaCodigo"
                  :dir="dir"
                  label="code"
                  :options="monedasModal"
                  value="id"
                  @input="cambiar(this.monedaBase)"
                >
                  <span slot="no-options">No hay opciones disponibles</span>
                </v-select>
              </b-form-group>
            </b-col>
            <!-- <b-col>
              <b-form-group
                label="Simbolo"
                label-for="select-moneda"
                label-class="font-weight-bolder label-size"
                class="line"
              >
                <v-select
                  v-if="currency.monedaCodigo != ''"
                  id="select-moneda"
                  :dir="dir"
                  :options="monedasModal"
                  label="symbol"
                  value="id"
                  v-model="currency.monedaCodigo"
                >
                  <span slot="no-options">No hay opciones disponibles</span>
                </v-select>
              </b-form-group>
            </b-col> -->
          </b-row>
        </b-form-group>

        <b-form-group
          label="Nombre "
          label-for="precio"
          label-class="font-weight-bolder label-size"
        >
        
          <v-select
            v-if="currency.monedaCodigo != ''"
            id="selectMonedaName"
            :dir="dir"
            :options="monedasModal"
            label="name"
            value="id"
            v-model="currency.monedaCodigo"
            class="mb-2"
          >
            <span slot="no-options">No hay opciones disponibles</span>
          </v-select>
        </b-form-group>

        <b-form-group>
          <b-row>
            <b-col class="col-md-6 col-12">
         
              <b-form-group
                :label="'Unidad en ' + monedaBase.name + ' *'"
                label-for="precio"
                label-class="font-weight-bolder label-size"
              >
                <b-form-input
                  type="number"
                  id="selectMonedaName"
                  v-model="currency.unidadMonedaBase"
                  :dir="dir"
                  label="title"
                  :options="optionNombreMonedas"
                />
              </b-form-group>
            </b-col>
            <b-col class="col-md-6 col-12">
              <b-form-group
                label="Unidades equivalentes*"
                label-for="select-moneda"
                label-class="font-weight-bolder label-size"
                class="line"
              >
                <b-form-input
                  type="number"
                  id="select-moneda"
                  :dir="dir"
                  :options="optionSimbolMonedas"
                  title="Simbolo"
                  v-model="currency.unidadMonedaExtra"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <div class="my-1">
          <b-alert
            v-height-fade.appear
            :show="dismissCountDownAddTarifa"
            dismissible
            variant="warning"
            id="empty-field-add-tarifa-popup"
            @dismissed="dismissCountDownAddTarifa = 0"
            @dismiss-count-down="countDownChangedAddTarifa"
          >
            <div class="alert-body">
              <span class="bold">Advertencia! </span>
              <span>Debes completar los campos obligatorios </span>
            </div>
          </b-alert>
        </div>

        <b-row align-h="between">
          <b-col cols="6">
            <b-button
              variant="outline-primary"
              class="mr-1 modal-button-size shadow-none"
              @click="hideAgregarModal"
            >
              Cancelar
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button
              variant="primary"
              class="modal-button-size shadow-none"
              @click="addMoneda"
            >
              Agregar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <!-- MODIFICAR PRECIO -->
    <b-modal
      id="modal-editar"
      title="Modificar precio"
      ok-title="Modificar"
      ok-variant="primary shadow-none modal-button-size"
      cancel-title="Cancelar"
      cancel-variant="outline-primary shadow-none modal-button-size"
      hide-footer
      v-model="modalEditarShow"
      centered
      class="w-50"
      ref="modal-editar"
    >
      <b-form>
        <b-form-group>
          <b-row>
            <b-col>
              <b-form-group
                label="Codigo *"
                label-for="select-codigo"
                label-class="font-weight-bolder label-size"
              >
                <v-select
                  id="selectMonedaName"
                  v-model="currency.monedaCodigo"
                  :dir="dir"
                  label="title"
                  :options="optionMonedas"
                  title="Codigo"
                >
                  <span slot="no-options">No hay opciones disponibles</span>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Simbolo *"
                label-for="select-moneda"
                label-class="font-weight-bolder label-size"
                class="line"
              >
                <v-select
                  id="select-moneda"
                  :dir="dir"
                  :options="optionSimbolMonedas"
                  title="Simbolo"
                  v-model="currency.monedaSymbol"
                >
                  <span slot="no-options">No hay opciones disponibles</span>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group
          label="Nombre*"
          label-for="precio"
          label-class="font-weight-bolder label-size"
        >
          <v-select
            id="selectMonedaName"
            v-model="currency.monedaName"
            :dir="dir"
            :options="optionNombreMonedas"
            class="mb-2"
          >
            <span slot="no-options">No hay opciones disponibles</span>
          </v-select>
        </b-form-group>

        <b-form-group>
          <b-row>
            <b-col>
              <b-form-group
                :label="'Unidad en ' + monedaBase.name + '*'"
                label-for="precio"
                label-class="font-weight-bolder label-size"
              >
                <b-form-input
                  type="number"
                  id="selectMonedaName"
                  v-model="currency.unidadMonedaBase"
                  :dir="dir"
                  label="title"
                  :options="optionNombreMonedas"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Unidades equivalentes *"
                label-for="select-moneda"
                label-class="font-weight-bolder label-size"
                class="line"
              >
                <b-form-input
                  type="number"
                  id="select-moneda"
                  :dir="dir"
                  :options="optionSimbolMonedas"
                  title="Simbolo"
                  v-model="currency.unidadMonedaExtra"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <b-row align-h="between">
          <b-col>
            <b-button
              variant="outline-primary"
              class="modal-button-size shadow-none"
              @click="hideEditarModal"
            >
              Cancelar
            </b-button>
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              class="modal-button-size shadow-none"
              @click="
                () => {
                  saveChange();
                  showPrecioEditadoExitosoToast('success');
                  hideEditarModal();
                }
              "
            >
              Modificar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <!-- ELIMINAR TARIFA -->
    <b-modal
      id="modal-eliminar-tarifa"
      body-bg-variant="light-warning"
      v-model="modalEliminartarifaShow"
      hide-footer
      hide-header
      centered
      size="sm"
      ref="modal-eliminar-tarifa"
    >
      <label id="label-confirmacion">
        ¿ Seguro que quieres eliminar esta tarifa?
      </label>
      <b-row>
        <b-col>
          <b-button
            variant="warning"
            class="mr-1 modal-button-size shadow-none"
            @click="hideEliminarModal"
          >
            Cancelar
          </b-button>
          <b-button
            variant="outline-warning"
            class="modal-button-size shadow-none"
            @click="deleteCurrency()"
          >
            Eliminar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- CANCELAR CREAR PRODUCTO NUEVO -->
    <b-modal
      id="modal-Cancelar"
      body-bg-variant="light-warning"
      v-model="modalCancelarShow"
      hide-footer
      hide-header
      centered
      size="sm"
      ref="modal-Cancelar"
    >
      <label id="label-confirmacion-cancelar">
        ¿ Seguro que quieres cancelar?
      </label>
      <b-row>
        <b-col>
          <b-button
            variant="warning"
            class="mr-1 modal-button-size shadow-none"
            @click="hideCancelarModal"
          >
            No
          </b-button>
          <router-link to="/dashboard/productos">
            <b-button
              variant="outline-warning"
              class="modal-button-size shadow-none"
            >
              Sí
            </b-button>
          </router-link>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BCard,
  BTable,
  BFormDatepicker,
  BModal,
  BFormRadio,
  BAlert,
  BFormTextarea,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import moment from 'moment';
import Button from '@/views/components/button/Button.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { heightFade } from '@core/directives/animations';
import Ripple from 'vue-ripple-directive';
import axios from 'axios';
import { backendUrl } from '@/config';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BCard,
    BTable,
    Button,
    BFormDatepicker,
    BModal,
    BFormRadio,
    ToastificationContent,
    BAlert,
    BFormTextarea,
  },
  methods: {
    ver(){
      console.log('monedaelegida::', this.monedaBase)
      this.monedasModal = this.optionMonedas.filter(
        (x) => x.id != this.monedaBase.id
      );
        console.log('monedasodal::', this.monedasModal )
    },
    async cargarPasarelas() {
      const token = localStorage.getItem('token');

      var config = {
        method: 'get',
        url: `${backendUrl}/operaciones/util`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const res = await axios(config);
        return res;
      } catch (error) {
      }
    },

    datosEditar(datos) {
      this.currency.unidadMonedaExtra = datos.unidadMonedaExtra;
      this.currency.monedaCodigo = datos.code;
      this.currency.monedaName = datos.nombre;
      // this.currency.monedaSymbol = datos.simbolo;
      this.currency.unidadMonedaBase = datos.unidadMonedaBase;

      this.modalEditarShow = !this.modalEditarShow;
    },
    saveChange() {
      const paraTabla = {
        precio: '',
        code: '',
        nombre: '',
        // simbolo: '',
        unidadMonedaBase: null,
        unidadMonedaExtra: null,
      };
      let tablaEditar = [];

      tablaEditar = this.paraTabla.filter(
        (e) => !e.nombre.includes(this.currency.monedaName)
      );

      paraTabla.precio =
        +this.currency.unidadMonedaExtra / +this.currency.unidadMonedaBase;
      paraTabla.code = this.currency.monedaCodigo.code;
      paraTabla.nombre = this.currency.monedaCodigo.name;
      // paraTabla.simbolo = this.currency.monedaCodigo.symbol;
      paraTabla.unidadMonedaBase = this.currency.unidadMonedaBase;
      paraTabla.unidadMonedaExtra = this.currency.unidadMonedaExtra;

      tablaEditar.push(paraTabla);
      this.paraTabla = tablaEditar;
      this.tableData = this.paraTabla;
      this.currency.unidadMonedaExtra = '';
      this.currency.monedaCodigo = '';
      // this.currency.monedaName = '';
      // this.currency.monedaSymbol = '';
      // this.currency.unidadMonedaBase = '';
    },
    cambiar(moneda) {
      this.moneda = moneda;
      this.monedaBase = this.moneda;

    },
    hideEditarModal() {
      this.$refs['modal-editar'].hide();
    },
    hideAgregarModal() {
      this.$refs['modal-agregar'].hide();
    },
    hideEliminarModal() {
      this.$refs['modal-eliminar-tarifa'].hide();
    },
    hideCancelarModal() {
      this.$refs['modal-Cancelar'].hide();
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    countDownChangedAddTarifa(dismissCountDown) {
      this.dismissCountDownAddTarifa = dismissCountDown;
    },
    showAlertAddTarifa() {
      this.dismissCountDownAddTarifa = this.dismissSecsAddTarifa;
    },
    showProductoCreadoExitosoToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se ha creado un Producto exitosamente!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showProductoCreadoErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al Agregar un Nuevo Producto',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showCredencialCreadaExitosoToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se han guardado las credenciales exitosamente!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showCredencialCreadaErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al Guardar credenciales',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    getMediosPago(pasarelas) {
      let result = [];
      let aux = [];
      for (let index = 0; index < pasarelas.length; index++) {
        const element = pasarelas[index];
        if (element == 'Stripe') result.push(...this.optionMediosStripe);
        if (element == 'Kushki') result.push(...this.optionMediosKushki);
        if (element == 'Suscripciones Móviles')
          result.push(...this.optionMediosSusM);
      }

      aux = result.filter(function(item, pos, self) {
        return self.indexOf(item) == pos;
      });

      return aux;
    },
    getMonedas(pasarelas){
      console.log('pasare:::', pasarelas)
      let result = [];
      let aux = [];
      for (let index = 0; index < pasarelas.length; index++) {
        const element = pasarelas[index];
        if (element == 'Stripe') result.push(...this.optionMonedas);
        if (element == 'Kushki') result.push(...this.optionMonedasKushki);
        if (element == 'Suscripciones Móviles')
          result.push(...this.optionMonedasKushki.filter((m)=> m.code == 'CLP'));
      }

      aux = result.filter(function(item, pos, self) {
        return self.indexOf(item) == pos;
      });

      return aux;
    },

    showNombreProductoExistente(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Ya existe un producto con ese nombre',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },

    showPrecioCreadoExitosoToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se ha agregado un Nuevo Precio exitosamente!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showPrecioEditadoExitosoToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se ha modificado el Precio exitosamente!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showPrecioCreadoErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al Agregar un Nuevo Precio',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showPrecioEditadoErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al Modificar el Precio',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showEmailPasswordExitosoToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se ha enviado un email de contraseña generada exitosamente!',

          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showEmailPasswordErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al enviar email de contraseña generada',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    addMoneda() {
      const paraTabla = {
        precio: '',
        code: '',
        nombre: '',
        simbolo: '',
        unidadMonedaBase: null,
        unidadMonedaExtra: null,
      };

      if (
        this.currency.unidadMonedaBase != '' &&
        this.currency.unidadMonedaExtra != '' &&
        this.currency.monedaCodigo != ''
        // this.currency.monedaSymbol != '' &&
        // this.currency.monedaName != ''
      ) {
        paraTabla.precio =
          +this.currency.unidadMonedaBase / +this.currency.unidadMonedaExtra;
        paraTabla.code = this.currency.monedaCodigo.code;
        paraTabla.nombre = this.currency.monedaCodigo.name;
        // paraTabla.simbolo = this.currency.monedaCodigo.symbol;
        paraTabla.unidadMonedaBase = this.currency.unidadMonedaBase;
        paraTabla.unidadMonedaExtra = this.currency.unidadMonedaExtra;

        this.paraTabla.push(paraTabla);

        this.tableData = this.paraTabla;
        this.showPrecioCreadoExitosoToast('success');
        this.hideAgregarModal();

        this.currency.unidadMonedaExtra = '';
        this.currency.monedaCodigo = '';
        this.currency.monedaName = '';
        this.currency.unidadMonedaBase = '';
      } else {
        this.showAlertAddTarifa();
      }
    },
    areyousure(item) {

      this.modalEliminarTarifaShow = !this.modalEliminarTarifaShow;
      this.currencyDeleteElement = item;
    },
    onChangePasarela() {
      this.medios = [];
    },
    deleteCurrency() {
      this.modalEliminarTarifaShow = !this.modalEliminarTarifaShow;
    
      this.tableData.splice(
        this.tableData.indexOf(this.currencyDeleteElement),
        1
      );
      this.hideEliminarModal();
    },
    async validateEmptyFields() {

      if (
        (this.nombre != '' &&
          this.servicios.length > 0 &&
          this.medios.length > 0 &&
          this.pasarelasProducto.length > 0 &&
          this.monedaBase != '' &&
          this.terminosCondiciones != '') &&
          (((this.pasarelasProducto.indexOf('Stripe') > -1 &&
          this.clavePrivadaStripe != '' &&
          this.clavePublicaStripe != '') ||
        (this.pasarelasProducto.indexOf('Kushki') > -1 &&
          this.clavePublicaKushki != '' &&
          this.clavePrivadaKushki != '')) ||
          (this.pasarelasProducto.indexOf('Suscripciones Móviles') > -1))
      ) {
        this.$store.commit('appConfig/changeLoading', true);
        const token = localStorage.getItem('token');

        
        let currency = [];
        if (this.tableData.length > 0) {
          for (let q = 0; q < this.tableData.length; q++) {
             let currency_ref = this.$store.state.appConfig.currency.find((m)=> m.code == this.tableData[0].code)
          
            const data = {
            id_currency_ref: currency_ref.id,
            equivalent_amount: this.tableData[q].precio,
          };

            currency.push(data);
          }
        }
        //credenciales
        let credentials = [];

        if (this.pasarelasProducto.indexOf('Stripe') > -1) {
          const dataStripe = {
            id_gateway: 2,
            credentials: {
              publicKey: this.clavePublicaStripe,
              privateKey: this.clavePrivadaStripe,
            },
            showCredentials: 1,
          };

          

          credentials.push(dataStripe);
        }
        if (this.pasarelasProducto.indexOf('Kushki') > -1) {
          const dataKushki = {
            id_gateway: 1,
            credentials: {
              publicKey: this.clavePublicaKushki,
              privateKey: this.clavePrivadaKushki,
            },
            showCredentials: 1,
          };


          credentials.push(dataKushki);
        }
        if (this.pasarelasProducto.indexOf('Suscripciones Móviles') > -1) {
          const dataSM = {
            id_gateway: 3,
            credentials: {},
            showCredentials: 1,
          };


          credentials.push(dataSM);
        }

        
        var data = JSON.stringify({
          name: this.nombre,
          card_enable: this.medios.includes('Tarjeta') ? true : false,
          movistar_enable: this.medios.includes('Movistar') ? true : false,
          entel_enable: this.medios.includes('Entel') ? true : false,
          trasfer_enable: this.medios.includes('Transferencia') ? true : false,
          cash_enable: this.medios.includes('Efectivo') ? true : false,
          suscription_enable: this.servicios.includes('Suscripciones')
            ? true
            : false,
          link_enable: this.servicios.includes('Links de Pago') ? true : false,
          discount_enable: this.servicios.includes('Códigos de Descuento')
            ? true
            : false,
          id_currency: this.monedaBase.id,
           url_discount: this.codigo,
          Terms_and_Conditions: this.terminosCondiciones,
          // country: [...paises],
          currency: currency,
          credentials: credentials,
          //TODO codigo de descuento
        });
        console.log(JSON.parse(data))
        

        var config = {
          method: 'post',
          url: `${backendUrl}/productos/add/`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          data: data,
        };

        let isProductCreated = false;

        try {
          const respuesta = await axios(config);
         
          const users = await axios({
            method: 'get',
            url: `${backendUrl}/usuarios/`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const user = users.data.user;

          const usuario = await user.filter(
            (e) => e.id === this.$store.state.appConfig.usuarioLogeado.id
          );

          const productosdb = await axios({
            method: 'get',
            url: `${backendUrl}/productos/`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const productos = productosdb.data.project;

          const producto = await productos.filter(
            (e) => e.id === this.$store.state.appConfig.projecto.id
          );

          let nuevoProd = respuesta.data.producto;

          this.$store.commit('appConfig/saveProyectosTotales', productos);

          let productosStore = [];

          for (let f = 0; f < usuario[0].project.length; f++) {
            const resProj = await productos.filter(
              (e) => e.id === usuario[0].project[f].id_project
            );
            for (let k = 0; k < resProj.length; k++) {
              productosStore.push({
                id: resProj[k].id,
                name: resProj[k].name,
                currency: resProj[k].id_currency,
                terms: resProj[k].Terms_and_Conditions,
              });
            }
          }

          this.$store.commit('appConfig/changeProyectosStore', productosStore);

          this.$store.commit('appConfig/changeStore', {
            id: nuevoProd.id,
            name: nuevoProd.name,
            id_currency: nuevoProd.id_currency,
            Terms_and_Conditions: nuevoProd.Terms_and_Conditions,
          });

         
          this.$store.commit('appConfig/changeLoading', false);
          this.showProductoCreadoExitosoToast('success');
          this.$router.push('/dashboard/productos');
        } catch (error) {
          if (!isProductCreated) this.showNombreProductoExistente('danger');
          else this.showProductoCreadoErrorToast('danger');
          this.$store.commit('appConfig/changeLoading', false);
        }
      } else {
        this.showAlert();
        this.nombre == ''
          ? (this.aceptadoNombre = false)
          : (this.aceptadoNombre = true);
        this.monedaBase == ''
          ? (this.aceptadoMoneda = false)
          : (this.aceptadoMoneda = true);
       
        this.servicios.length == 0
          ? (this.aceptadoServicios = false)
          : (this.aceptadoServicios = true);
        this.medios.length == 0
          ? (this.aceptadoMedios = false)
          : (this.aceptadoMedios = true);
        this.pasarelasProducto.length == 0
          ? (this.aceptadoPasarela = false)
          : (this.aceptadoPasarela = true);
        this.terminosCondiciones == ''
          ? (this.aceptadoTermsCond = false)
          : (this.aceptadoTermsCond = true);
      
          this.pasarelasProducto.map((x) => x.title).indexOf("Stripe") > -1 &&
        this.clavePublicaStripe != ""
          ? (this.aceptadoClavePublicaStripe = true)
          : (this.aceptadoClavePublicaStripe = false);
        this.pasarelasProducto.map((x) => x.title).indexOf("Stripe") > -1 &&
        this.clavePrivadaStripe != ""
          ? (this.aceptadoClavePrivadaStripe = true)
          : (this.aceptadoClavePrivadaStripe = false);
        this.pasarelasProducto.map((x) => x.title).indexOf("Kushki") > -1 &&
        this.clavePrivadaKushki != ""
          ? (this.aceptadoClavePrivadaKushki = true)
          : (this.aceptadoClavePrivadaKushki = false);
        this.pasarelasProducto.map((x) => x.title).indexOf("Kushki") > -1 &&
        this.clavePublicaKushki != ""
          ? (this.aceptadoClavePublicaKushki = true)
          : (this.aceptadoClavePublicaKushki = false);
      }
    },
    async getUtils() {
      const token = localStorage.getItem('token');

      var config = {
        method: 'get',
        url: `${backendUrl}/operaciones/util`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const resUtils = await axios(config);

        const {pasarelas } = resUtils.data.response;
        let monedas = this.$store.state.appConfig.currency

        this.dataMonedas = monedas;

        this.pasarelas = pasarelas;
  
        for (let i = 0; i < this.pasarelas.length; i++) {
          this.optionPasarelas.push(this.pasarelas[i].nombre);
        }

        this.optionMonedas = monedas  

        this.optionMonedasKushki = monedas.filter((x)=>x.kushkiEnable == 1)
     
      } catch (error) {
      }
    },
  },
  directives: {
    'height-fade': heightFade,
    Ripple,
  },
  data() {
    return {
      optionMonedasKushki:[],
      terminosCondiciones: '',
      tableData: [],
      modalShow: false,
      modalEditarShow: false,
      modalEliminartarifaShow: false,
      modalCancelarShow: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      dismissSecsAddTarifa: 5,
      dismissCountDownAddTarifa: 0,

      dir: 'ltr',
      optionMedios: ['Tarjeta'],
      //Hardcodeado. Usar optionMedios para traer medios desde la bd
      optionMediosKushki: ['Tarjeta', 'Transferencia', 'Efectivo'],
      optionMediosStripe: ['Tarjeta'],
      optionMediosSusM: ['Entel', 'Movistar'],
      // -----------------------------------------------------------
      optionServicios: [
        'Suscripciones',
        'Links de Pago',
        'Códigos de Descuento',
      ],
      
      optionPaises: [],
      dataPaises: [],
      fields: [
        // { key: 'id', label: 'ID' },
        { key: 'code', label: 'CODE' },
        { key: 'nombre', label: 'NOMBRE' },
        { key: 'precio', label: 'PRECIO' },
        { key: 'accion', label: 'ACCION' },
      ],
      optionMonedas: [],
      optionNombreMonedas: [],
      optionSimbolMonedas: [],
      optionCodeMonedas: [],
      dataMonedas: [],
      monedaBase: '',
      monedasModal: '',
      moneda: [],
      errorEmptyFields: false,
      //DATOS PRODUCTO NUEVO
      nombre: '',
      medios: [],
      servicios: [],
      codigo: '',
      // paises: [],
      // currency:'',
      currency: {
        monedaName: '',
        monedaSymbol: '',
        monedaCodigo: '',
        unidadMonedaBase: '',
        unidadMonedaExtra: '',
      },
      paraTabla: [],
      pasarelas: [],
      optionPasarelas: [],
      pasarelasProducto: [],

      //CAMPOS OBLIGATORIOS
      aceptadoNombre: null,
      aceptadoPasarela: null,
      aceptadoMedios: null,
      aceptadoServicios: null,
      aceptadoNombre: null,
      aceptadoPais: null,
      aceptadoMoneda: null,
      aceptadoTermsCond: null,
      aceptadoTarifas: true,
      aceptadoDescuento: null,
      aceptadoClavePublicaStripe: null,
      aceptadoClavePrivadaStripe: null,
      aceptadoClavePublicaKushki: null,
      aceptadoClavePrivadaKushki: null,
      //CREDENCIALES
      clavePrivadaStripe: '',
      clavePublicaStripe: '',
      clavePublicaKushki: '',
      clavePrivadaKushki: '',
    };
  },
  mounted() {
    if (
      this.$store.state.appConfig.rol != 3 ||
      this.$store.state.appConfig.rol == 0
    ) {
      this.$router.push({ path: '/' });
    } else {
      this.getUtils();
    }
  },

  setup() {
    const productData = ref({
      nombre: '',
      medios: [],
      servicios: [],
      codigo: '',
      // paises: [],
    });

    const tarifaDataInfo = ref({
      precio: 'CLP 100.00',
      tipoPago: '',
      pasarelas: [],
      periodo: '',
      moneda: '',
    });

    const pagoOptions = [
      { text: 'Pago Único', value: 'Pago Único' },
      { text: 'Pago Recurrente', value: 'Pago Recurrente' },
    ];

    return {
      productData,
      pagoOptions,
      tarifaDataInfo,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#selectPasarelas .vs__selected-options .vs__selected,
#selectProductos .vs__selected-options .vs__selected,
#selectMediosPago .vs__selected-options .vs__selected,
#selectServicios .vs__selected-options .vs__selected,
#selectPais .vs__selected-options .vs__selected {
  background: $info;
  border-radius: 20px;
}

.action-button {
  border: none;
  background: transparent;
  width: 18px;
}

#label-confirmacion {
  margin-left: 12%;
  margin-bottom: 16px;
  color: $warning !important;
  font-weight: bold;
  font-size: 14px;
}

#label-confirmacion-cancelar {
  margin-left: 22%;
  margin-bottom: 16px;
  color: $warning !important;
  font-weight: bold;
  font-size: 14px;
}

#empty-field-popup {
  width: 100%;
}

#cancel-button {
  width: 146.22px;
}

.data-items-size {
  font-size: 12px;
}

::v-deep .table th {
  text-align: center !important;
}

.label-subtitles {
  font-size: 15px;
  font-weight: bold;
}

.modal-button-size {
  width: 166px;
}

.bold {
  font-weight: bold;
}
//SPINNER
#loading-logo {
  width: 50%;
}
#loading-bg {
  width: 100%;
  height: 100%;
  background: transparent;
  display: block;
  position: absolute;
}
.loading-logo {
  position: absolute;
  left: calc(46% - 45px);
  top: 40%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

#label-pasarela-visible {
  font-size: 13px;
  font-weight: bolder;
  margin: 4px 0 4px 0;
}
</style>
